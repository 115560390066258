@import 'variables';
@import 'definitions';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400&display=swap');

body, .p-component {
  font-family: 'Montserrat', sans-serif !important;
}

.app {  
  display: grid; 
  grid-template-columns: var(--nav-full-width) 1fr; 
  grid-template-rows: var(--top-full-height) 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "main-nav top"
    "main-nav main-content";
  height: 100vh;

  &.no-menu {
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "main-content";

      .main-nav, .top { display: none !important; 
        }
      
  }

  .p-menuitem-text{
    font-size:8px !important;
  }

  .main-nav { grid-area: main-nav; 
    }
  .top { grid-area: top; }
  .main-content { grid-area: main-content;
    }

  .top {
    @extend .box-shadow;
    z-index: 1;
    background: var(--top-color);
  }

  .main-nav {
    background-color: var(--nav-color);
    color: var(--nav-text-color);
    // height: 100vh;
    z-index: 2;
  }

  .main-content {
    background-color: var(--main-content-color);
    padding: 5px;
  }

  h1 {
    font-weight: 100;
  }

  .section-header {
    height: 2.5rem;
    color: white;
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    fill: var(--nav-text-color);
  }

  .hotspot {
    cursor: pointer;
  }

  .text-main {
    color: white;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 2;
  }
}

.p-dialog-header {
  padding: 1rem !important;
  
}
//////////////// TABLE

.p-selection-column {
  width: 2rem !important;
  
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: 300 ;
  font-size: 12px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: var(--color-light-gray) !important;
    
    
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.1rem 1rem !important;
  font-size: 12px;
  
}

.p-paginator,  .p-paginator-bottom {
  padding: 0.1rem 1rem !important;
  font-size: 10px !important;
  font-weight: 550 !important;
  
  
}

.p-inputtext {
  padding: 0.1rem 0.75rem !important;
  font-size: 12px !important;
}

.datatable-responsive {
  opacity: .8;
  height: 100%
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.details-section-column {
  width: 50%;
  font-size: 12px !important;
  background: var(--color-light-gray) !important;
  &:first-child {
    border-right: 1px solid #e9ecef;
  }
}

.details-section-table {
 
  font-size: 12px !important;
  background: var(--color-light-gray) !important;
  &:first-child {
    border-right: 1px solid #e9ecef;
  }
}

@media screen and (max-width: 800px) {
  .logo-label {
    display: none !important;
  }
  .app {  
    grid-template-columns: var(--nav-small-width) 1fr; 
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody {
    display: table-caption;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr {
    display: table;
    width: 100%;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
      text-align: left;
      display: flex;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
      padding: .4rem;
      min-width: 40%;
      display: inline-block;
      margin: -.4em 1em -.4em -.4rem;
      font-weight: bold;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid #dcdcdc;
  }

  .details-section {
    flex-direction: column;
  }

  .details-section-column {
    width: 100%;

    &:first-child {
      border-right: none;
    }
  }
}