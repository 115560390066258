:root {
    // sizes
    --nav-full-width: 170px;
    --nav-small-width: 70px;
    --top-full-height: 40px;

    // colors
    // --color-main: #3f4d67;
    --color-main: #607D8B;
    --color-light-gray: #f3f6fb;
    --color-gray: #c8ced6;
    // --color-accent: #18abb2;
    --color-accent: #b6f2f5;
    // --color-accent: #2196f3;
    --color-label: white;
    --color-label-hover: gray;
    --color-none: transparent;
}